// extracted by mini-css-extract-plugin
export var buttons = "style-module--buttons--TJ+z3";
export var container = "style-module--container--UL684";
export var isStyleH1 = "style-module--is-style-h1--0XyIi";
export var isStyleH2 = "style-module--is-style-h2--S4-pZ";
export var isStyleH3 = "style-module--is-style-h3--SIaCB";
export var isStyleH4 = "style-module--is-style-h4--nhGH4";
export var isStyleH5 = "style-module--is-style-h5--m5Afa";
export var isStyleH6 = "style-module--is-style-h6--6ua5I";
export var section = "style-module--section--6j9+h";
export var title = "style-module--title--hPbtm";