import React, { useState } from "react"
import "./style.sass"

const ContactContent = () => {
  const [showForm, setShowForm] = useState(true)

  return (
    <div id="contact_content">
      <div className="container">
        <h2>Payment details</h2>

        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          className="payment-details"
        >
          <input type="hidden" name="cmd" value="_xclick" />

          <input
            type="hidden"
            name="image_url"
            value="//www.actionstep.com/wp-content/themes/actionstep/images/site/logo.png"
          />

          <input type="hidden" name="business" value="RSAJBQN3UYQZG" />

          <input
            type="hidden"
            name="custom"
            value="actionstep.com/pay-online"
          />

          <input type="hidden" name="hosted_button_id" value="KBYZ56N6D8JFU" />

          <fieldset>
            <div class="row">
              <div class="col-sm-5">
                <label>Choose your currency:</label>
              </div>

              <div class="col-sm-7">
                <select name="currency_code">
                  <option value="USD" selected="selected">
                    US Dollars - USD
                  </option>

                  <option value="AUD">Australian Dollars - AUD</option>

                  <option value="NZD">New Zealand Dollars - NZD</option>

                  <option value="EUR">Euros - EUR</option>

                  <option value="GBP">British Pounds - GBP</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <label class="required">Payment reference:</label>
              </div>

              <div class="col-sm-7">
                <input type="text" name="item_name" />

                <br />

                <small>
                  <em>e.g PLL4050, demo22</em>
                </small>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <label class="required">Amount due:</label>
              </div>

              <div class="col-sm-7">
                <input type="text" name="amount" placeholder="0.00" />
              </div>
            </div>

            <div class="field submit">
              <input
                class="btn btn-primary"
                type="submit"
                name="submit"
                value="Pay now by Credit Card"
                alt="PayPal - The safer, easier way to pay online!"
              />
            </div>

            <div class="payment-method">
              <a
                onclick="javascript:window.open('https://www.paypal.com/cgi-bin/webscr?cmd=xpt/Marketing/popup/OLCWhatIsPayPal-outside','olcwhatispaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=400, height=350');"
                href="#"
              >
                <img
                  src="https://www.paypal.com/en_US/i/bnr/horizontal_solution_PPeCheck.gif"
                  alt="Solution Graphics"
                  border="0"
                />
              </a>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  )
}

export default ContactContent
